import _get from 'lodash/get'
import { get as getConfig } from 'config'
import { campaignShouldRender, getVariation } from 'services/testarossa'
import {
  isAccountCancelFlow,
  isAccountPauseFlow,
  isCheckoutFlowPage,
  isMyAccountPage,
  isCartBillingPayment,
  isLive,
} from 'services/url'

const config = getConfig()
const enabled = _get(config, ['features', 'zendeskChat'])
const zendeskCheckoutFlowEnabled = _get(config, ['features', 'zendeskChatOnCheckoutFlowEnabled'])// Documentation for the chat widget
// https://api.zopim.com/files/meshim/widget/controllers/LiveChatAPI-js.html

const MAX_ATTEMPTS = 10

let currentLocation = null
let initialized = false
let timer = null
let attempts = 0

function shouldShow () {
  const embed = _get(currentLocation, 'query.embed')

  if (embed === 'true') {
    return false
  }

  let showChat = false

  const { pathname } = currentLocation
  if (campaignShouldRender({ campaign: 'ME-2809' })) {
    const variation = getVariation({ campaign: 'ME-2809' })
    if (_get(variation, 'friendlyId') === 1) {
      const visibilityRules = _get(variation, 'criteria').find((i) => _get(i, 'type') === 'VISIBILITY')
      const benchmarks = _get(visibilityRules, 'benchmarks')
      const allowedPaths = benchmarks.map((i) => _get(i, 'value'))
      showChat = allowedPaths.includes(pathname)
    }
  }

  // keep cancel and checkout outside of experiment
  if (
    isAccountCancelFlow(pathname)
    || isAccountPauseFlow(pathname)
    || isMyAccountPage(pathname)
    || (zendeskCheckoutFlowEnabled && isCheckoutFlowPage(pathname))
  ) {
    showChat = true
  }

  if (
    isCartBillingPayment(pathname)
    || isLive(pathname)
  ) {
    showChat = false
  }

  return showChat
}

function waitForZE (location, language, isLoggedIn) {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
  if (attempts < MAX_ATTEMPTS) {
    timer = setTimeout(() => {
      attempts += 1
      zendesk(location, language, isLoggedIn)
    }, 1000)
  }
}

export default function zendesk (location, language, isLoggedIn) {
  // im adding this because of the 'setOnStatus'
  // event listener. If CR comes online or goes offline while a visitor is on a page,
  // we need to keep the page context when the status changes so that we can appropriately
  // show or hide the chat wiget.
  currentLocation = location

  // This for the standard message widget
  // Just uncomment this, and comment out the webWidget code below
  if (enabled) {
    const { zE } = global
    if (zE) {
      if (!initialized) {
        initialized = true
      }
      // Update the Widget's language properties
      zE('messenger:set', 'locale', language)
      if (shouldShow()) {
        zE('messenger', 'show')
      } else {
        zE('messenger', 'hide')
      }
      return
    }
    waitForZE(location, language, isLoggedIn)
  }

  // This is for the live chat widget
  // Just comment this out, and uncomment the message code above if desired
  // if (enabled) {
  //   const { zE } = global
  //   if (zE) {
  //     if (!initialized) {
  //       initialized = true
  //       // setup and theme
  //       // Explicitly set the Widget's language properties
  //       zE('webWidget', 'setLocale', language)
  //       // hide the chat widget by default
  //       zE('webWidget', 'hide')
  //       // Toggle visibility if CR is online
  //       zE('webWidget:on', 'chat:status', (status) => {
  //         if (status === 'online') {
  //           if (shouldShow()) {
  //             zE('webWidget', 'show')
  //           }
  //         } else {
  //           zE('webWidget', 'hide')
  //         }
  //       })
  //     }
  //     // Update the Widget's language properties
  //     zE('webWidget', 'setLocale', language)
  //     if (shouldShow()) {
  //       zE('webWidget', 'show')
  //     } else {
  //       zE('webWidget', 'hide')
  //     }
  //     return
  //   }
  //   waitForZE(location, language, isLoggedIn)
  // }
}
