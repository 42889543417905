import { Map } from 'immutable'
import _toString from 'lodash/toString'
import {
  SET_INBOUND_TRACKING_DATA,
  setInboundTracking,
} from 'services/inbound-tracking/actions'
import { isReferJoin, isPortal } from 'services/url'
import { get as getLocalInboundTrackingData } from 'services/inbound-tracking'
import { getAuthIsLoggedIn } from 'services/auth'
import { getEverFlowScript } from '.'

function isEverFlowTriggerPage ({ state }) {
  const { resolver, auth } = state
  const path = resolver.getIn(['data', 'path'])

  // only fire for anonymous users on certain pages
  return (
    !getAuthIsLoggedIn(auth) && (isReferJoin(path) || isPortal(path))
  )
}

/* eslint-disable import/prefer-default-export */
export function watchEverFlowTrackingData ({ after }) {
  return after([
    SET_INBOUND_TRACKING_DATA,
  ], async ({ state, action, dispatch }) => {
    const { user } = state
    const { payload } = action
    const uid = user.getIn(['data', 'uid'], -1) || -1
    const payloadAffid = payload.data?.get('affiliateId') ? _toString(payload.data.get('affiliateId')) : undefined
    const payloadOid = payload?.data.get('offerId') ? _toString(payload.data.get('offerId')) : undefined
    const payloadTransactionId = payload.data.get('transactionId')
    const localInboundTrackingData = await getLocalInboundTrackingData({ uid }) || Map()
    const localAffid = localInboundTrackingData.get('affiliateId') ? _toString(localInboundTrackingData.get('affiliateId')) : undefined
    const localOid = localInboundTrackingData.get('offerId') ? _toString(localInboundTrackingData.get('offerId')) : undefined
    const localTransactionId = localInboundTrackingData.get('transactionId')

    // If there is already a transactionId in local storage
    // and the affids and oids are equal, we don't need to do anything
    // so just bail
    if (localTransactionId && payloadAffid === localAffid && payloadOid === localOid) {
      return
    }

    // If there is a transactionId in the payload, then just bail
    // we don't need to do anything
    if (payloadTransactionId) {
      return
    }

    if (!payloadAffid || !payloadOid) {
      return
    }

    // load the EverFlow SDK
    await getEverFlowScript()
    const { EF } = global

    if (EF) {
      EF.click({
        offer_id: payloadOid,
        affiliate_id: payloadAffid,
      })
        .then((efTransactionId) => {
          // transactionId containts the unique Everflow transaction ID
          dispatch(setInboundTracking({
            affiliateId: payloadAffid,
            offerId: payloadOid,
            transactionId: efTransactionId,
          }))
        })
    }
  })
    .when(isEverFlowTriggerPage)
}
