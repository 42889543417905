import _get from 'lodash/get'
import { get as getConfig } from 'config'
import { Map, List } from 'immutable'
import _toString from 'lodash/toString'
import _isUndefined from 'lodash/isUndefined'
import { SET_RESOLVER_DATA } from 'services/resolver/actions'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { BOOTSTRAP_PHASE_COMPLETE } from 'services/app'
import { set as setFeatureTracking, get as getFeatureTracking } from 'services/feature-tracking'
import { setFeatureTrackingData } from 'services/feature-tracking/actions'
import { NAVIGATION_HISTORY_PUSH, replaceHistory } from 'services/navigation/actions'
import { TYPE_PAUSE_ERROR, TYPE_PAUSE_RESUME, TYPE_LOGIN } from 'services/dialog'
import {
  DISMISS_MODAL,
  setOverlayDialogVisible,
  setOverlayCloseOnClick,
  setDialogOptions,
} from 'services/dialog/actions'
import { getAuthIsLoggedIn } from 'services/auth'
import {
  URL_ACCOUNT_CANCEL,
  URL_ACCOUNT_CANCEL_OFFER,
  URL_ACCOUNT_CANCEL_CONFIRM,
  URL_ACCOUNT_CANCEL_FREE_MONTH,
  URL_HOME,
  URL_ACCOUNT,
  URL_ACCOUNT_PAUSE_CONFIRM,
  URL_ACCOUNT_PAUSE,
  URL_ACCOUNT_CHANGE_PLAN,
  URL_ACCOUNT_INVOICE,
} from 'services/url/constants'
import { isAccountPage as isAccountPageUrl } from 'services/url'
import {
  PLAN_TYPE_UPGRADE,
  PLAN_TYPE_DOWNGRADE,
  getPlanSubscriptionType,
  determinePlanUpgradeDowngrade,
} from 'services/plans'
import {
  FORM_SUBMISSION_NAME_CANCEL_FLOW,
  post as postFormSubmissions,
} from 'services/form-submissions'
import {
  SET_FORM_SUBMISSIONS_PROCESSING,
  SET_FORM_SUBMISSIONS_CONFIRMED_DATA,
} from 'services/form-submissions/actions'
import {
  doAuthRenew,
  SET_AUTH_LOGIN_SUCCESS,
  SET_AUTH_DATA,
} from 'services/auth/actions'
import { USER_PROFILES_SET } from 'services/user-profiles/actions'
import { setInterstitial } from 'services/interstitial/actions'
import { SET_USER_DATA } from 'services/user/actions'
import { COMP_USER_SUBSCRIPTION_DATA } from 'services/subscription/actions'
import { SET_PAYTRACK_DATA_LAST_TRANSACTION } from 'services/paytrack/actions'
import { postBrooklyn as postEmailCapture } from 'services/email-signup'
import {
  setDefaultGaEvent,
  setDefaultGa4Event,
} from 'services/event-tracking/actions'
import {
  ACCOUNT_PAUSED_GA4_EVENT,
  ACCOUNT_UNPAUSED_GA4_EVENT,
  buildCheckoutEcommerceObject,
  PURCHASE_PLAN_UPGRADE_GA4_EVENT,
} from 'services/event-tracking'
import { INTERSTITIAL_SELECT_PROFILE } from 'services/interstitial'
import { format as formatDateTime, getDateTime } from 'services/date-time'
import { parse as parseQuery } from 'services/query-string'
import {
  updateUserAccountResume,
  updateUserAccountPause,
  getUserSubscriptionPayments,
  setZuoraUserAccountSubscriptionCancelConfirmed,
  changeUserAccountPlanTypeDataZuora,
  USER_ACCOUNT_CANCELLED,
  hasScheduledPause,
  isPrimaryAccountProfile,
  getUserAccountInvoiceHistory,
  getUserAccountInvoice,
  isGaiaDirectPayment,
  updateBillingAccountZuoraPaypal,
  getUserAccountType,
} from '.'
import * as actions from './actions'

const config = getConfig()
const cancelV2 = _get(config, ['features', 'userAccount', 'cancelV2'])

function shouldGetSubscriptionPayments ({ state }) {
  const { resolver, auth } = state
  const path = resolver.getIn(['data', 'path'])

  return (
    (
      path === URL_ACCOUNT_CANCEL_FREE_MONTH
      || path === URL_ACCOUNT_CANCEL
      || path === URL_ACCOUNT
      || path === URL_ACCOUNT_PAUSE
    ) && auth.get('jwt')
  )
}

function cancelFlowAccessDenied ({ state }) {
  const { resolver, auth } = state
  const path = resolver.getIn(['data', 'path'])

  return (
    (path === URL_ACCOUNT_CANCEL || path === URL_ACCOUNT_CANCEL_OFFER || path === URL_ACCOUNT_CANCEL_CONFIRM) && !auth.get('jwt') && cancelV2
  )
}

function cancelFlowAccess ({ state }) {
  const { resolver, auth } = state
  const path = resolver.getIn(['data', 'path'])

  return (
    (path === URL_ACCOUNT_CANCEL || path === URL_ACCOUNT_CANCEL_OFFER || path === URL_ACCOUNT_CANCEL_CONFIRM) && auth.get('jwt') && cancelV2
  )
}

async function getUserPayments (state, dispatch) {
  const { auth, userAccount } = state
  const accountId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountId'])
  const accountNumber = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountNumber'])

  if (accountId && accountNumber) {
    dispatch(actions.setUserAccountSubscriptionPaymentsProcessing(true))
    const payments = await getUserSubscriptionPayments({ accountId, accountNumber, auth })
    dispatch(actions.setUserAccountSubscriptionPaymentsData(payments))
  }
}

export function watchUpdateBillingAccountZuoraPaypal ({ after }) {
  return after([
    actions.UPDATE_USER_BILLING_ADDRESS_ZUORA_PAYPAL,
  ], async ({ state, action, dispatch }) => {
    const { auth, userAccount } = state
    const { payload = {} } = action
    const { postalCode, requireZipcodeUpdate2021 } = payload
    const accountId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountId'])
    const accountNumber = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountNumber'])
    const response = await updateBillingAccountZuoraPaypal({
      auth, postalCode, accountId, accountNumber,
    })

    if (requireZipcodeUpdate2021 && response.success) {
      await setFeatureTracking({ auth, data: { zipcodeUpdate2021: true } })
      const trackingData = await getFeatureTracking({ auth })
      dispatch(setFeatureTrackingData(trackingData, false))
    }

    dispatch({
      type: actions.UPDATED_USER_BILLING_ADDRESS_ZUORA_PAYPAL,
      payload: response,
    })
  })
}

export function watchCancelPageSetResolverData ({ after }) {
  return after([
    SET_RESOLVER_DATA,
  ], async ({ dispatch, state }) => {
    getUserPayments(state, dispatch)
  }).when(shouldGetSubscriptionPayments)
}

export function watchCancelPageBillingSubscriptionsData ({ after }) {
  return after(
    [
      actions.SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
    ],
    async ({ dispatch, state }) => {
      getUserPayments(state, dispatch)
    },
  )
    .when(shouldGetSubscriptionPayments)
}

export function watchCancelFlowSetResolverData ({ after }) {
  return after([
    SET_RESOLVER_DATA,
  ], async ({ dispatch }) => {
    dispatch({
      type: NAVIGATION_HISTORY_PUSH,
      payload: { url: URL_HOME },
    })
  }).when(cancelFlowAccessDenied)
}

// -----------------------------------
// Watcher for checking data "server side"
// -----------------------------------
export function watchCancelFlowAccessDeniedBootstrapPhase ({ before }) {
  return before(SET_APP_BOOTSTRAP_PHASE, async ({ dispatch, state, action }) => {
    const { app } = state
    const { payload } = action
    const currentBootstrapComplete = app.get('bootstrapComplete')
    const nextBootstrapComplete = _get(payload, 'isComplete')

    // only fire once
    if (nextBootstrapComplete && nextBootstrapComplete !== currentBootstrapComplete) {
      dispatch({
        type: NAVIGATION_HISTORY_PUSH,
        payload: { url: URL_HOME },
      })
    }
  })
    .when(cancelFlowAccessDenied)
}

// Watch for user account cancel
export function watchUserAccountCancel ({ after }) {
  return after([
    actions.SET_USER_ACCOUNT_CANCEL,
  ], async ({ dispatch, state }) => {
    const { auth } = state

    try {
      dispatch({
        type: actions.SET_USER_ACCOUNT_CANCEL_CONFIRM_PROCESSING,
        payload: true,
      })
      const data = await setZuoraUserAccountSubscriptionCancelConfirmed({ auth })
      await dispatch(doAuthRenew(auth))
      dispatch({
        type: actions.SET_USER_ACCOUNT_CANCEL_CONFIRM_DATA,
        payload: { data, processing: false },
      })
    } catch (e) {
      dispatch({
        type: actions.SET_USER_ACCOUNT_CANCEL_CONFIRM_PROCESSING,
        payload: false,
      })
      dispatch({
        type: actions.SET_USER_ACCOUNT_CANCEL_CONFIRM_DATA,
        payload: { data: { success: false }, processing: false },
      })
    }
  })
    .when(cancelFlowAccess)
}

export function watchUserAccountCancelConfirmData ({ after }) {
  return after([
    actions.SET_USER_ACCOUNT_CANCEL_CONFIRM_DATA,
  ], async ({ dispatch, state }) => {
    const { userAccount, auth } = state
    const success = userAccount.getIn(['manageSubscription', 'data', 'cancelConfirmData', 'success'])
    const cancelFormData = userAccount.getIn(['manageSubscription', 'data', 'formData'], Map())
    const formNameCancelFlow = cancelFormData.get('formName') === FORM_SUBMISSION_NAME_CANCEL_FLOW

    if (success && formNameCancelFlow) {
      try {
        dispatch({
          type: SET_FORM_SUBMISSIONS_PROCESSING,
          payload: true,
        })
        const result = await postFormSubmissions({ auth, data: cancelFormData.toJS() })
        dispatch({
          type: SET_FORM_SUBMISSIONS_CONFIRMED_DATA,
          payload: { data: _get(result, 'data'), processing: false },
        })
      } catch (e) {
        dispatch({
          type: SET_FORM_SUBMISSIONS_PROCESSING,
          payload: false,
        })
      }
    }
  })
    .when(cancelFlowAccess)
}

export function watchCompUserSubscriptionData ({ after }) {
  return after([
    COMP_USER_SUBSCRIPTION_DATA,
  ], async ({ dispatch, state }) => {
    const { auth, subscription } = state
    const userCompSuccess = subscription.get('success')

    if (userCompSuccess) {
      await dispatch(doAuthRenew(auth))
    }
  })
    .when(cancelFlowAccess)
}

export function watchCancelFlowAccess ({ after }) {
  return after(
    [
      actions.SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
      SET_RESOLVER_DATA,
    ],
    async ({ dispatch, state }) => {
      const { userAccount, resolver } = state
      const path = resolver.getIn(['data', 'path'])
      const accountStatusCancelled = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'status']) === USER_ACCOUNT_CANCELLED
      const accountCancelReason = userAccount.getIn(['manageSubscription', 'data', 'cancelReason'])

      if (
        accountStatusCancelled
      && (path === URL_ACCOUNT_CANCEL || path === URL_ACCOUNT_CANCEL_OFFER)
      ) {
      // send the user to the account page
        dispatch({
          type: NAVIGATION_HISTORY_PUSH,
          payload: { url: URL_ACCOUNT },
        })
      } else if (
        !accountCancelReason
      && (path === URL_ACCOUNT_CANCEL_OFFER || path === URL_ACCOUNT_CANCEL_CONFIRM)
      ) {
      // send the user to the cancel page
        dispatch({
          type: NAVIGATION_HISTORY_PUSH,
          payload: { url: URL_ACCOUNT_CANCEL },
        })
      }
    },
  )
    .when(cancelFlowAccess)
}

// Account Pause v2
export function updateAccountPause ({ after }) {
  return after(actions.UPDATE_USER_ACCOUNT_PAUSE, async ({ action, state, dispatch }) => {
    const { auth } = state
    const { payload: months } = action
    const data = { months }
    const payload = await updateUserAccountPause({
      data,
      auth,
    })

    if (_get(payload, 'success')) {
      // GA3 event
      dispatch(setDefaultGaEvent(Map({
        event: 'customEvent',
        eventCategory: 'Account Pause',
        eventAction: 'Paused',
        eventLabel: months,
      })))

      // GA4 event
      const eventData = ACCOUNT_PAUSED_GA4_EVENT
        .mergeDeep({
          pause_duration: months,
        })

      dispatch(setDefaultGa4Event(eventData))
    }

    return dispatch({
      type: actions.SET_USER_ACCOUNT_PAUSE,
      payload,
    })
  })
}

export function setAccountPause ({ after }) {
  return after(actions.SET_USER_ACCOUNT_PAUSE, async ({ action, dispatch, state }) => {
    const { auth } = state
    const { payload } = action
    if (_get(payload, 'success')) {
      await dispatch(doAuthRenew(auth))

      return dispatch({
        type: NAVIGATION_HISTORY_PUSH,
        payload: { url: URL_ACCOUNT_PAUSE_CONFIRM },
      })
    }

    return false
  })
}

export function clearPauseErrors ({ takeLatest }) {
  return takeLatest(DISMISS_MODAL, () => {
    return {
      type: actions.RESET_USER_ACCOUNT_PAUSE_ERRORS,
    }
  })
    .when(({ action }) => _get(action, ['payload', 'clickedOverlay']) === TYPE_PAUSE_ERROR
      || _get(action, ['payload', 'clickedOverlay']) === TYPE_PAUSE_RESUME)
}

export function resumeAccount ({ after }) {
  return after(actions.UPDATE_USER_ACCOUNT_RESUME, async ({ state, dispatch }) => {
    const { auth, userAccount } = state
    const pauseAmount = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'pauseMonths'])
    const payload = await updateUserAccountResume({ auth })

    if (_get(payload, 'success')) {
      // GA3 event
      dispatch(setDefaultGaEvent(Map({
        event: 'customEvent',
        eventCategory: 'Account Pause',
        eventAction: 'Pause Removed',
        eventLabel: pauseAmount,
      })))

      // GA4 event
      dispatch(setDefaultGa4Event(ACCOUNT_UNPAUSED_GA4_EVENT))
    }

    return dispatch({
      type: actions.SET_USER_ACCOUNT_RESUME,
      payload,
    })
  })
}

export function watchResumeAccount ({ after }) {
  return after(actions.SET_USER_ACCOUNT_RESUME, async ({ action, state, dispatch }) => {
    const { payload } = action
    const { auth } = state

    if (_get(payload, 'success')) {
      await dispatch(doAuthRenew(auth))

      return dispatch({
        type: NAVIGATION_HISTORY_PUSH,
        payload: { url: URL_ACCOUNT },
      })
    }

    return false
  })
}

export function clearManageSubscription ({ after }) {
  return after(NAVIGATION_HISTORY_PUSH, async ({ action, dispatch }) => {
    const { payload } = action
    const shouldReset = [URL_ACCOUNT, URL_ACCOUNT_PAUSE_CONFIRM].includes(_get(payload, 'url'))

    if (shouldReset) {
      return dispatch({
        type: actions.RESET_USER_ACCOUNT_MANAGE_SUBSCRIPTION_DATA,
      })
    }

    return false
  })
}

export function watchUpdateUserAccountPlan ({ after }) {
  return after(actions.UPDATE_USER_ACCOUNT_PLAN, async ({ action, state, dispatch }) => {
    const { payload } = action
    const {
      userAccount, plans, user, page, auth, inboundTracking,
    } = state
    try {
      dispatch({
        type: actions.CHANGE_USER_ACCOUNT_CHANGE_PLAN_TYPE_PROCESSING,
        payload: true,
      })
      // if the user has a scheduled pause, remove it
      if (hasScheduledPause(userAccount)) {
        await updateUserAccountResume({ auth })
      }
      const data = await changeUserAccountPlanTypeDataZuora(payload)

      // send analytics tracking to GA and Emarsys
      if (data.get('success')) {
        const email = user.getIn(['data', 'mail'])
        const locale = page.get('locale')
        const dateFormatString = 'YYYY-MM-DD'
        const currentPlanId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'plan', 'productRatePlanId'])
        const selectedPlanSku = plans.getIn(['changePlanData', 'selectedPlan'])
        const selectedPlan = selectedPlanSku ? plans.getIn(['data', 'plans'], Map()).find((plan) => {
          return plan.get('sku') === selectedPlanSku
        }) : Map()
        const planName = selectedPlan.get('heading', '')
        const planCost = selectedPlan.getIn(['costs', 0], 0)
        const planCategory = getPlanSubscriptionType(selectedPlan.get('id'))
        const selectedPlanId = selectedPlan.get('id')
        const planUpgradeDownGradeType = determinePlanUpgradeDowngrade(
          selectedPlanId,
          currentPlanId,
        )

        const today = formatDateTime(undefined, locale, dateFormatString)
        const fields = {
          entitled: true,
        }

        // Determine upgrade or downgrade once the plan change is COMPLETED for Emarsys endpoint
        if (planUpgradeDownGradeType === PLAN_TYPE_UPGRADE) {
          fields.plan_start_date = today
          fields.cart_upgrade_complete_date = today
        } else if (planUpgradeDownGradeType === PLAN_TYPE_DOWNGRADE) {
          fields.cart_downgrade_complete_date = today
        }

        postEmailCapture({
          email,
          fields,
          source: '',
          inboundTracking,
        })

        if (selectedPlan.size > 0) {
          const orderId = data.get('orderNumber') || null

          // GA3 EVENT
          dispatch(setDefaultGaEvent(Map({
            event: 'purchase',
            ecommerce: {
              purchase: {
                actionField: {
                  id: orderId,
                  revenue: _toString(planCost),
                  tax: '',
                  shipping: '',
                  coupon: '',
                },
                products: [
                  {
                    name: `${planName} - Upgrade`,
                    id: selectedPlanSku,
                    quantity: 1,
                    price: _toString(planCost),
                    category: planCategory,
                    coupon: '',
                  },
                ],
              },
            },
          })))

          // GA4 EVENT
          dispatch(setDefaultGa4Event(
            PURCHASE_PLAN_UPGRADE_GA4_EVENT.mergeDeep(
              buildCheckoutEcommerceObject(selectedPlan, orderId),
            ),
          ))
        }

        // renew auth to get entitlements
        await dispatch(doAuthRenew(auth))
      }

      dispatch({
        type: actions.CHANGE_USER_ACCOUNT_PLAN_TYPE_SET_DATA,
        payload: data,
      })
    } catch (e) {
      dispatch({
        type: actions.CHANGE_USER_ACCOUNT_CHANGE_PLAN_TYPE_PROCESSING,
        payload: false,
      })
    }
  })
}

function isAccountUrl ({ state }) {
  const { resolver } = state
  const path = resolver.getIn(['data', 'path'])
  return isAccountPageUrl(path)
}

// trigger the login modal for any anonymous account page
export function watchAccountChangePlanAndPausePageMount ({ after }) {
  return after(
    [
      SET_APP_BOOTSTRAP_PHASE,
      SET_RESOLVER_DATA,
    ],
    async ({ dispatch, state }) => {
      const { auth } = state
      if (!getAuthIsLoggedIn(auth)) {
        dispatch(setOverlayDialogVisible(TYPE_LOGIN))
        dispatch(setDialogOptions(null, true))
        dispatch(setOverlayCloseOnClick(false))
      }
    },
  )
    .when(isAccountUrl)
}

function isAccountChangePlanPage ({ state }) {
  const { resolver } = state
  const path = resolver.getIn(['data', 'path'])
  return path === URL_ACCOUNT_CHANGE_PLAN
}

export function watchSelectProfileAfterLoginInchangePlanPage ({ after }) {
  return after(USER_PROFILES_SET, async ({ state, prevState, dispatch }) => {
    const { userProfiles } = state
    const { userProfiles: prevUserProfiles } = prevState
    const shouldPromptProfileSelector = userProfiles.get('data', List()).size > 0 && prevUserProfiles.get('data', List()).size === 0
    if (shouldPromptProfileSelector) {
      dispatch(setInterstitial(INTERSTITIAL_SELECT_PROFILE))
    }
  }).when(isAccountChangePlanPage)
}

function isAccountPauseOrCancelPage ({ state }) {
  const { resolver } = state
  const path = resolver.getIn(['data', 'path'])
  return path === URL_ACCOUNT_CANCEL || path === URL_ACCOUNT_PAUSE
}

export function watchPauseCancelPageRedirectChild ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
    SET_RESOLVER_DATA,
    SET_USER_DATA,
  ], async ({ state, dispatch }) => {
    const { userProfiles } = state
    const profilesReady = userProfiles.get('data', List()).size > 0
    const isPrimaryProfile = isPrimaryAccountProfile(state)
    if (profilesReady && !isPrimaryProfile) {
      dispatch(replaceHistory({ url: URL_ACCOUNT }))
    }
  }).when(isAccountPauseOrCancelPage)
}

function isAccountPage ({ state }) {
  const { resolver, auth } = state
  const path = resolver.getIn(['data', 'path'])

  return path === URL_ACCOUNT && getAuthIsLoggedIn(auth)
}

export function watchUserAccountPageInvoiceHistory ({ after }) {
  return after([
    SET_RESOLVER_DATA,
    actions.SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
  ], async ({ dispatch, state }) => {
    const { auth, userAccount } = state
    const accountId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountId'])
    const accountNumber = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountNumber'])

    const invoiceHistoryProcessing = userAccount.get('billingInvoiceHistoryProcessing')

    if (!invoiceHistoryProcessing) {
      dispatch({
        type: actions.SET_USER_ACCOUNT_INVOICE_HISTORY_PROCESSING,
        payload: true,
      })
      const data = await getUserAccountInvoiceHistory({ auth, accountId, accountNumber })
      dispatch({
        type: actions.SET_USER_ACCOUNT_INVOICE_HISTORY_DATA,
        payload: { data, processing: false },
      })
    }
  }).when(isAccountPage)
}

function isAccountInvoicePage ({ state }) {
  const { resolver, auth } = state
  const path = resolver.getIn(['data', 'path'])

  return path === URL_ACCOUNT_INVOICE && getAuthIsLoggedIn(auth)
}

export function watchUserAccountInvoicePage ({ after }) {
  return after([
    actions.SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
  ], async ({ dispatch, state }) => {
    const { auth, userAccount, paytrack } = state
    const accountId = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountId'])
    const accountNumber = userAccount.getIn(['details', 'data', 'billing', 'subscriptions', 'billingAccountNumber'])
    const billedDirectly = isGaiaDirectPayment(userAccount, paytrack)

    if (!billedDirectly) {
      return
    }

    const { invoiceId } = parseQuery(global.location.search)

    // only make the api call for the invoice if the user is directly billed (not 3rd party)
    if (invoiceId && accountId && accountNumber) {
      dispatch({
        type: actions.SET_USER_ACCOUNT_INVOICE_PROCESSING,
        payload: true,
      })
      const data = await getUserAccountInvoice({
        auth, accountId, accountNumber, invoiceId,
      })
      dispatch({
        type: actions.SET_USER_ACCOUNT_INVOICE_DATA,
        payload: { data, processing: false },
      })
    }
  }).when(isAccountInvoicePage)
}

export function watchUserAccountType ({ after }) {
  return after([
    actions.SET_USER_ACCOUNT_DATA_BILLING_SUBSCRIPTIONS_WITH_DETAILS,
    SET_PAYTRACK_DATA_LAST_TRANSACTION,
    SET_USER_DATA,
    SET_AUTH_LOGIN_SUCCESS,
  ], async ({ dispatch, state }) => {
    const {
      auth,
      userAccount,
      paytrack,
      user,
    } = state
    const dataReady = auth.size && user.size && userAccount.size && paytrack.size

    if ((auth.get('processing') || user.get('processing') || userAccount.get('billingSubscriptionsProcessing') || paytrack.get('processing')) || !dataReady) {
      return
    }

    dispatch({
      type: actions.SET_USER_ACCOUNT_TYPE,
      payload: getUserAccountType(state),
    })
  })
}

// Watcher for setAuthData, which is called on login and renew
export function watchSetAuthData ({ after }) {
  return after([
    SET_AUTH_DATA,
  ], async ({ dispatch, state, prevState }) => {
    const { auth } = state
    const { auth: prevAuth } = prevState
    const lastBillingEvent = auth.get('lastBillingEvent')
    const prevLastBillingEvent = prevAuth.get('lastBillingEvent')

    // if prevLastBillingEvent is undefined,
    // then this is a fresh login and we need to get account details.
    // Or if the prevLastBillingEvent is null, and the lastBillingEvent is not undefined or null,
    // then get the account details.
    // Or if prevLastBillingEvent is not null or undefined,
    // and lastBillingEvent is not null or undefined,
    // and the lastBillingEvent date milliseconds
    // is greater than the prevLastBillingEvent date milliseconds,
    // then get account details.
    if (
      _isUndefined(prevLastBillingEvent)
        || (prevLastBillingEvent === null && lastBillingEvent)
        || (prevLastBillingEvent && lastBillingEvent
          && getDateTime(lastBillingEvent) > getDateTime(prevLastBillingEvent))
    ) {
      dispatch(actions.getUserAccountDataBillingSubscriptionsWithDetails({ auth }))
    }
  })
}

// Watcher for hard page refresh when logged in
export function watchAppBootstrapPhase ({ after }) {
  return after([
    SET_APP_BOOTSTRAP_PHASE,
  ], async ({ dispatch, state, action }) => {
    const { auth } = state
    const { payload = {} } = action
    const { phase } = payload
    const isLoggedIn = getAuthIsLoggedIn(auth)

    if (phase === BOOTSTRAP_PHASE_COMPLETE && isLoggedIn) {
      dispatch(actions.getUserAccountDataBillingSubscriptionsWithDetails({ auth }))
    }
  })
}
