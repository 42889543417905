import _map from 'lodash/map'
import _get from 'lodash/get'
import { fromJS } from 'immutable'
import {
  variationShouldRender,
  removeListener,
  addListener,
  isOperational,
  setContext,
  getSubject,
  getContext,
} from 'services/testarossa'
import {
  setAutoCompleteSearchData,
  setAutoCompleteSearchProcessing,
  setAutoCompleteSearchError,
  GET_AUTOCOMPLETE_SEARCH_DATA,
} from './actions'
import { getSearchAutocomplete } from '.'

const log = console

async function getAutoCompleteSearchDataHandler ({ store, action }) {
  const { auth, user } = store.getState()
  const bucketed = getSubject({
    campaign: 'AC-8825',
  }).status === 'active'

  let handler

  // TODO this retaining old data on user switch at the header level search form
  handler = async () => {
    const isGPSEEnabled = variationShouldRender({ campaign: 'AC-8825', variation: 1 })
    const data = await getSearchAutocomplete({
      auth,
      language: user.getIn(['data', 'language', 0]),
      gpse: (isGPSEEnabled && 1) || 0,
      value: action.payload,
    })
    store.dispatch(setAutoCompleteSearchData({
      data: fromJS(_map(data, 'value')),
    }))
    removeListener('resourcer.updated', handler)
    handler = null
  }

  try {
    store.dispatch(setAutoCompleteSearchProcessing({}))
    // bucket user in test if needed and add gpse flag
    const contextPath = _get(getContext(), 'path')
    if (isOperational() && !bucketed && contextPath !== 'search-autocomplete') {
      addListener('resourcer.updated', handler)
      setContext(() => ({ path: 'search-autocomplete' }))
    } else {
      // non test experience
      await handler()
    }
  } catch (error) {
    log.error(error, 'Error getting titles data for autosuggest.')
    store.dispatch(setAutoCompleteSearchError({
      error,
    }))
  }
}

/**
 * Redux middleware
 * @param {import('redux').Store} store A redux store
 * @returns {import('redux').Middleware} Redux middleware
 */
export default function middleware (store) {
  return (next) => (action) => {
    const { type } = action
    switch (type) {
      case GET_AUTOCOMPLETE_SEARCH_DATA:
        getAutoCompleteSearchDataHandler({ store, action })
        break
      default:
        break
    }
    next(action)
  }
}
