import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { withFormsy } from 'formsy-react'
import { connect as connectStaticText } from 'components/StaticText/connect'
import { getClass, getLabelId, getInputId } from './utils'

/**
 * @param {String} value
 * @param {Object} [ restrictions ]
 * @param {String} [ restrictions.min ]
 * @param {String} [ restrictions.max ]
 * @returns {String}
 */
function getSafeValue (value, restrictions = {}) {
  if (isNil(value) || value.startsWith('0')) {
    return value
  }
  const { min, max } = restrictions
  if (!isNil(min)) {
    const valueDate = new Date(value)
    const minDate = new Date(min)
    if (valueDate < minDate) {
      return min
    }
  }

  if (!isNil(max)) {
    const valueDate = new Date(value)
    const maxDate = new Date(max)
    if (valueDate > maxDate) {
      return max
    }
  }
  return value
}

class DateInputBase extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showError: false,
    }
  }

  getError () {
    const { staticText } = this.props

    const { showError } = this.state

    if (showError) {
      const { showRequired } = this.props
      if (showRequired) {
        return staticText.getIn(['data', 'required'])
      }

      // Formsy props
      const { errorMessage, isPristine } = this.props
      if (errorMessage && !isPristine) {
        return errorMessage
      }
    }
    return null
  }

  /**
   * @param {import('react').FocusEvent} e event
   */
  onBlur = (e) => {
    const { value } = e.currentTarget
    // formsy props
    const { isValidValue } = this.props

    this.setState({
      showError: !isValidValue(value),
    })
  }

  /**
   * @param {import('react').ChangeEvent} e event
   */
  onChange = (e) => {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    this.props.setValue(e.currentTarget.value)
  }

  handleKeyDown = () => {
    const {
      value = '',
      min,
      max,
    } = this.props
    const safeValue = getSafeValue(value, { min, max })

    if (
      safeValue === value
      && value !== ''
      && !value.startsWith('0')) this.props.setValue('')
  }

  render () {
    const {
      name,
      label,
      required = false,
      value = '',
      min,
      max,
      readonly,
      hidden = false,
    } = this.props

    const labelId = getLabelId(name)
    const id = getInputId(name)
    const error = this.getError()
    const className = getClass('date', this.props, error)
    const safeValue = getSafeValue(value, { min, max })
    return (
      <div className={className}>
        <input
          data-testid="date"
          type="date"
          className="forminput__input"
          aria-labelledby={labelId}
          required={required}
          pattern="\d{4}-\d{2}-\d{2}"
          value={safeValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyDown={this.handleKeyDown}
          min={min}
          max={max}
          readOnly={readonly}
          hidden={hidden}
          id={id}
        />
        <label
          className="forminput__label"
          tabIndex="-1"
          htmlFor={id}
        >
          {label}
        </label>
        {error ? (
          <span role="alert" aria-label={error} aria-live="polite" className="forminput__error">
            {error}
          </span>
        ) : null}
      </div>
    )
  }
}

DateInputBase.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  tabIndex: PropTypes.string,
  hidden: PropTypes.bool,
}

const FormsyDateInput = compose(
  connectStaticText({ storeKey: 'formInputV2' }),
  withFormsy,
)(DateInputBase)

export const DateInput = (props) => {
  return (
    <FormsyDateInput
      valitations={{
        matchRegexp: /^\d{4}-\d{2}-\d{2}$/,
      }}
      {...props}
    />
  )
}
DateInput.propTypes = DateInputBase.propTypes

export default DateInput
