import { Map, List } from 'immutable'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import { get as getMediaData } from 'services/media'
import { map as mapPromise } from 'bluebird'
import { TYPE_CONTENT_SERIES } from 'services/content-type'
import { getFeaturedEpisodeData } from 'services/user-node-info'
import { getAuthIsLoggedIn } from 'services/auth'
import * as actions from './actions'

function getExtraTileData ({ state }) {
  const { auth } = state

  return getAuthIsLoggedIn(auth)
}

/* eslint-disable import/prefer-default-export */
export function watchAppendTilesDataUserInfo ({ after }) {
  return after([
    actions.APPEND_TILES_DATA_USER_INFO,
  ], async ({ dispatch, state, action }) => {
    const { payload } = action
    const { storeKey, userInfo = List() } = payload
    const { tiles, auth, user } = state
    const tileStore = tiles.get(storeKey, Map())

    if (!getAuthIsLoggedIn(auth)) {
      return
    }

    if (!userInfo || _size(userInfo) <= 0) {
      return
    }

    dispatch(actions.setTilesUserInfoMediaProcessing(storeKey, true))

    const nodeInfo = userInfo.map((info) => {
      const id = _get(info, 'id')
      const tile = tileStore.getIn(['data', 'titles'], List()).find((n) => id === n.get('nid')) || Map()

      return {
        mediaId: tile.getIn(['feature', 'id']),
        id,
      }
    })

    const mediaResponses = await mapPromise(nodeInfo, async (i) => {
      try {
        const data = await getMediaData({ id: _get(i, 'mediaId'), auth, user })
        const media = {
          textTracks: _get(data, 'textTracks', {}) || {},
          mediaLang: _get(data, 'mediaLang', ''),
          byLang: _get(data, 'byLang', {}) || {},
          id: _get(data, 'id', null) || null,
        }

        return { id: _get(i, 'id'), media }
      } catch (error) {
        return { id: _get(i, 'id'), error }
      }
    })

    dispatch(actions.appendTilesDataUserInfoMedia(storeKey, mediaResponses))
  }).when(getExtraTileData)
}

export function watchSetTilesData ({ after }) {
  return after([
    actions.SET_TILES_DATA,
    actions.APPEND_TILES_DATA_TITLES,
  ], async ({ dispatch, state, action }) => {
    const { payload } = action
    const { storeKey, data, titles } = payload
    const { auth, user } = state
    const tilesData = _get(data, 'titles') || titles || []
    const filteredTilesData = _filter(tilesData, (n) => {
      return _get(n, 'contentType') === TYPE_CONTENT_SERIES
    })
    const ids = _map(filteredTilesData, (n) => {
      return _get(n, 'id')
    })

    if (!_size(filteredTilesData)) {
      return
    }

    if (!_size(ids)) {
      return
    }

    dispatch(actions.setTilesFeaturedEpisodeProcessing(true))

    const featuredEpisodeData = await getFeaturedEpisodeData(ids, { auth, user })
    const filteredFeaturedEpisodeData = _filter(featuredEpisodeData, (i) => {
      return !_get(i, 'error')
    })

    if (filteredFeaturedEpisodeData) {
      dispatch(actions.appendTilesDataFeaturedEpisode(storeKey, filteredFeaturedEpisodeData))
    }
  }).when(getExtraTileData)
}
